import React from "react"

import { navigate } from "gatsby"

import PropTypes from "prop-types"

import AuthWrapper from "../components/auth/AuthWrapper"
import Button from "../components/common/Button"
import Input from "../components/common/Input"
import { AuthContext } from "../store"

const ResetPasswordPage = ({ location }) => {
  const { resetPassword } = React.useContext(AuthContext)
  const [password, setPassword] = React.useState("")

  const params = new URLSearchParams(location.search)
  const email = params.get("email")
  const token = params.get("token")

  React.useEffect(() => {
    if (!token) {
      navigate("/forgot-password")
    }
  }, [])

  const handleSubmit = (e) => {
    e.preventDefault()

    resetPassword({ email, token, password })
  }

  return (
    <AuthWrapper title="Set New Password" isForgotPassword>
      <form className="form" onSubmit={handleSubmit}>
        <Input
          type="password"
          id="password"
          label="password"
          placeholder="New Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          isBlock
          required
        />
        <Button type="submit" color="primary" isBlock>
          Reset Password
        </Button>
      </form>
    </AuthWrapper>
  )
}

ResetPasswordPage.propTypes = {
  location: PropTypes.object.isRequired,
}

export default ResetPasswordPage
